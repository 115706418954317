import styled, { css } from 'styled-components'

export const StyledPreview = styled.div`
    display: grid;
    grid-gap: 20px;
    grid-template-columns: 1fr 1fr;
    @media (max-width: 1160px) {
        grid-template-columns: initial;
    }
    .preview {
        display: grid;
        place-items: center;
    }
    .preview-title {
        color: ${({ theme }) => theme.colors.darkGunMetal};
        font-size: ${({ theme }) => theme.fontSizes.large};
        font-weight: ${({ theme }) => theme.fontWeights.bold};
        margin: 19px 0;
        opacity: 0.7;
        text-align: center;
        margin-bottom: 19px;
    }
    .customize {
        margin-top: 50px;
        display: grid;
    }
    .custom-text {
        width: 371px;
        height: 120px;
        background: ${({ theme }) => theme.colors.lotion};
        background: ${({ theme }) => theme.colors.lotion};
        border: 1px solid ${({ theme }) => theme.colors.taupeGray};
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        border-radius: 10px;
        color: ${({ theme }) => theme.colors.darkGunMetal};
        opacity: 0.7;
        font-size: ${({ theme }) => theme.fontSizes.normal};
        padding: 14px;
        font-weight: ${({ theme }) => theme.fontWeights.bold};
        font-family: ${({ theme }) => theme.fonts.inter}, sans-serif;
        margin: 0 auto;
        resize: none;
        @media (max-width: 760px) {
            width: 271px;
            height: 120px;
        }
    }
    .divider {
        border: 1px solid ${({ theme }) => theme.colors.dividerGrey};
        margin: 29px 0;
        height: 0;
    }
    .form-control {
        display: grid;
        grid-template-columns: 115px 1fr;
        margin-bottom: 30px;
        @media (max-width: 760px) {
            grid-column-gap: 10px;
            grid-template-columns: 95px 1fr;
        }
    }
    .form-label {
        color: ${({ theme }) => theme.colors.darkGunMetal};
        opacity: 0.7;
        font-size: ${({ theme }) => theme.fontSizes.normal};
        font-weight: ${({ theme }) => theme.fontWeights.bold};
        @media (max-width: 760px) {
            font-size: ${({ theme }) => theme.fontSizes.small};
        }
    }
    .share-icon {
        margin-right: 5px;
    }
    .form-social {
        margin: 0 auto 10px auto;
    }
    .image-slider {
        width: 200px;
    }
    .form-action {
        display: flex;
        justify-content: right;
        height: 50px;
        /* grid-template-columns: 200px 100px;
    margin: 0 auto; */
    }
    .download-button {
        background: ${({ theme }) => theme.colors.primary};
        border-radius: 5px;
        color: ${({ theme }) => theme.colors.white};
        border: none;
        padding: 15px 70px;
        text-transform: uppercase;
        cursor: pointer;

        &:disabled {
            background: ${({ theme }) => theme.colors.dividerGrey};
            cursor: not-allowed;
        }
    }
    .share-button {
        background: ${({ theme }) => theme.colors.charlestonGreen};
        border-radius: 5px;
        padding: 15px 11px;
        color: ${({ theme }) => theme.colors.white};
        border: none;
        margin-left: 10px;
        text-transform: uppercase;
        cursor: pointer;
    }
    .dropzone {
        text-align: center;
        padding: 20px;
        border: 3px dashed #eeeeee;
        background-color: #fafafa;
        color: ${({ theme }) => theme.colors.darkGunMetal};
        margin-bottom: 20px;
        font-family: ${({ theme }) => theme.fonts.inter}, sans-serif;
    }

    .accept {
        border-color: ${({ theme }) => theme.colors.primary} !important;
    }

    .reject {
        border-color: ${({ theme }) => theme.colors.red} !important;
    }
`

export const StyledPreviewImage = styled.div`
    ${(props) =>
    css`
            background: url(${props.image});
        `}
    background-size: cover;
    background-repeat: no-repeat;
    width: 550px;
    height: 800px;
    position: relative;
    @media (min-width: 320px) and (max-width: 759px) {
        width: 300px;
        height: 425px;
    }
    .preview-image-container {
        border-radius: 50%;
        position: absolute;
        // START: change this base on the image
        width: 190px;
        height: 190px;
        left: 56px;
        top: 364px;
        // END: change this base on the image
        overflow: hidden;
        @media (min-width: 320px) and (max-width: 759px) {
            // START: change this base on the image
            width: 107px;
            height: 107px;
            left: 27px;
            top: 191px;
            // END: change this base on the image
        }
    }
    .preview-image {
        border-radius: 50%;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
    .preview-text {
        color: ${({ theme }) => theme.colors.black};
        position: absolute;
        top: 350px; // change this base on the image
        left: 300px; // change this base on the image
        width: 235px;
        font-weight: ${({ theme }) => theme.fontWeights.bold};
        font-size: ${({ theme }) => theme.fontSizes.normal};
        text-shadow: 0.5px 0.5px ${({ theme }) => theme.colors.black};
        @media (min-width: 320px) and (max-width: 759px) {
            top: 200px; // change this base on the image
            left: 160px; // change this base on the image
            width: 135px;
            font-size: ${({ theme }) => theme.fontSizes.tiny};
            line-height: 1.2;
        }
    }
    a.preview-hidden {
        display: none;
    }
`
