import { Header, StyledAbout, StyledTable } from "./About.style";
import {
  EVENT_THEME,
  EVENT_TITLE,
  GUESTS_LIST,
  REGISTER_LINK,
  EVENT_DATE
} from "lib/home-constants";

const About = () => {
  return (
    <StyledAbout>
      <div className="divider" />
      <Header className="title">{EVENT_TITLE}</Header>
      <h4>
        <strong>
          Theme: <br /> {EVENT_THEME}
        </strong>
      </h4>
      <p className="content">
        You are specially invited to the upcoming {EVENT_TITLE} with the theme:{" "}
        {EVENT_THEME}
      </p>

      <p className="content">
        <strong>Date: </strong>
        <time dateTime={EVENT_DATE[0].shortdate}>
          {EVENT_DATE[0].fulldate}
        </time>
        - &nbsp;
        <time dateTime={EVENT_DATE[1].shortdate}>{EVENT_DATE[1].fulldate}</time>{" "}
      </p>
      <p className="content">
        <strong>Time: </strong>
        <time>6am-8am </time>, <time>8am-10am </time>, <time>10am-12noon </time>
        , <time> 12noon-2pm </time> and <time>4pm-9pm </time> daily (all GMT +1)
      </p>
      <address className="content">
        <strong>Venue:</strong> The Dream Centre Of the Life Oasis International
        Church HQ, Along Osun State Secretariat Abere, shortly before November
        27 Interchange, Osogbo, Osun State, Nigeria.
      </address>

      <StyledTable>
        <table className="ministering">
          <tr>
            <th colSpan={2}>Ministering</th>
          </tr>
          {GUESTS_LIST.map((guest) => (
            <tr key={guest.name}>
              <td>{guest.name}</td>
              <td>{guest.brief}</td>
            </tr>
          ))}
        </table>
      </StyledTable>

      <p className="content">
        <strong>Live streaming: </strong>
        <a
          href="http://www.lifeoasisinternationalchurch.org/streaming"
          target="_blank"
          rel="noreferrer"
        >
          http://www.lifeoasisinternationalchurch.org/streaming
        </a>
        , &nbsp;
        <a href="http://www.dciradio.org/" target="_blank" rel="noreferrer">
          http://www.dciradio.org/
        </a>
        &nbsp; and all our social media platforms.
      </p>
      <p className="content">
        <strong>Register at: </strong>
        <a href={REGISTER_LINK} target="_blank" rel="noreferrer">
          {REGISTER_LINK}
        </a>
      </p>

      <p className="content">
        <strong>Enquiries: </strong>
        <a className="tel" href="tel:+2349079912254">
          +2349079912254
        </a>
        <a className="tel" href="tel:+2348033879828">
          +2348033879828
        </a>
        <a className="tel" href="tel:+2348033506866">
          +2348033506866
        </a>
        <a className="tel" href="tel:+2347038548230">
          +2347038548230
        </a>
      </p>

      <div className="cta">
        <a
          href={REGISTER_LINK}
          target="_blank"
          rel="noreferrer"
          className="cta-button"
        >
          Register here
        </a>
      </div>
    </StyledAbout>
  );
};

export default About;
