import React from "react";
import styled from "styled-components";

export const StyledSlider = styled.input`
    -webkit-appearance: none;
    margin: 10px 0;
    width: 100%;
    &:focus {
        outline: none;
    }
    &::-webkit-slider-runnable-track {
        width: 100%;
        height: 3px;
        cursor: pointer;
        box-shadow: 0px 0px 0px #000000, 0px 0px 0px #0d0d0d;
        background: ${({ theme }) => theme.colors.richBlack};
        border-radius: 25px;
        border: 0px solid #000101;
    }
    &::-webkit-slider-thumb {
        box-shadow: 0px 0px 0px #000000, 0px 0px 0px #0d0d0d;
        border: 0px solid #000000;
        height: 22px;
        width: 22px;
        border-radius: 50%;
        background: ${({ theme }) => theme.colors.primary};
        cursor: pointer;
        -webkit-appearance: none;
        margin-top: -9.0px;
    }
    &:focus::-webkit-slider-runnable-track {
        background: ${({ theme }) => theme.colors.richBlack};
    }
    &::-moz-range-track {
        width: 100%;
        height: 3px;
        cursor: pointer;
        animate: 0.2s;
        box-shadow: 0px 0px 0px #000000, 0px 0px 0px #0d0d0d;
        background: ${({ theme }) => theme.colors.richBlack};
        border-radius: 25px;
        border: 0px solid #000101;
    }
    &::-moz-range-thumb {
        box-shadow: 0px 0px 0px #000000, 0px 0px 0px #0d0d0d;
        border: 0px solid #000000;
        height: 20px;
        width: 39px;
        border-radius: 7px;
        background: ${({ theme }) => theme.colors.primary};
        cursor: pointer;
    }
    &::-ms-track {
        width: 100%;
        height: 3px;
        cursor: pointer;
        animate: 0.2s;
        background: transparent;
        border-color: transparent;
        border-width: 39px 0;
        color: transparent;
    }
    &::-ms-fill-lower {
        background: ${({ theme }) => theme.colors.richBlack};
        border: 0px solid #000101;
        border-radius: 50px;
        box-shadow: 0px 0px 0px #000000, 0px 0px 0px #0d0d0d;
    }
    &::-ms-fill-upper {
        background: ${({ theme }) => theme.colors.richBlack};
        border: 0px solid #000101;
        border-radius: 50px;
        box-shadow: 0px 0px 0px #000000, 0px 0px 0px #0d0d0d;
    }
    &::-ms-thumb {
        box-shadow: 0px 0px 0px #000000, 0px 0px 0px #0d0d0d;
        border: 0px solid #000000;
        height: 20px;
        width: 39px;
        border-radius: 7px;
        background: ${({ theme }) => theme.colors.primary};
        cursor: pointer;
    }
    &:focus::-ms-fill-lower {
        background: ${({ theme }) => theme.colors.richBlack};
    }
    &:focus::-ms-fill-upper {
        background: ${({ theme }) => theme.colors.richBlack};
    }
`
