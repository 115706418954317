import styled from "styled-components";

export const StyledButton = styled.button`
  color: ${({ theme }) => theme.colors.testimony};
  margin: 1em;
  padding: 0.5em 1em;
  border: 1px solid ${({ theme }) => theme.colors.testimony};
  border-radius: 3px;
  background-color: white;
  cursor: pointer;
  text-transform: uppercase;
`;

export const StyledSamples = styled.div`
  display: grid;
  grid-gap: 20px;
  place-items: center;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  @media (min-width: 760px) and (max-width: 1140px) {
    grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  }
  @media (min-width: 320px) and (max-width: 760px) {
    grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
  }
  @media (max-width: 320px) {
    grid-template-columns: repeat(auto-fit, minmax(75px, 1fr));
  }
  img {
    width: 300px;
    height: 300px;
    cursor: pointer;
    @media (min-width: 760px) and (max-width: 1140px) {
      width: 150px;
      height: 150px;
    }
    @media (min-width: 320px) and (max-width: 760px) {
      width: 100%;
    }
    @media (max-width: 320px) {
      width: 75px;
      height: 75px;
    }
  }
  div {
    display: flex;
    align-items: center;
    flex-direction: column;
  }
  button {
    margin: 20px auto;
  }
  .selected {
    border: 1px solid none;
  }
`;