import { useState, useCallback, createRef, useEffect } from 'react';
import { StyledPreview, StyledPreviewImage } from "./Preview.styled"
import { notify } from 'react-notify-toast';
import { useDebounce } from "hooks/useDebounce"
import { toBlob } from "html-to-image";
import fileDownload from 'js-file-download'
import { isSafari, isIOS, isMobileSafari, isMacOs } from "react-device-detect";
import { DEFAULT_INVITE_TEXT, IMAGE_DOWNLOAD_FILE, IMAGE_DOWNLOAD_TITLE } from 'lib/testimony-constants';


const Preview = ({ selectedInvite }) => {
    const [customText, setCustomText] = useState(DEFAULT_INVITE_TEXT);
    const [previewImage, setPreviewImage] = useState(null);
    const [inviteFile, setInviteFile] = useState(null);
    const inviteRef = createRef(null)
    const [transformScale, setTransformScale] = useState(1.0);
    const debouncedCustomText = useDebounce(customText, 2000);

    const downloadInvite = () => {
        if (debouncedCustomText.trim() !== "") {
            notify.show("Downloading...", "success");
            fileDownload(inviteFile, IMAGE_DOWNLOAD_FILE);
        } else {
            notify.show("Please enter a message", "error");
        }
    }

    const handleShare = async () => {
        if (inviteFile && navigator.share) {
            const data = {
                files: [
                    new File([inviteFile], IMAGE_DOWNLOAD_FILE, {
                        type: inviteFile.type
                    })
                ],
                title: IMAGE_DOWNLOAD_TITLE,
                text: debouncedCustomText
            };
            try {
                await navigator.share(data);
            } catch (err) {
                console.error(err);
            }
        }
    };

    const makeImage = useCallback(async () => {
        let newFile;
        /**
         * On iOS, Safari devices html is converted to png without the
         * images included in the html block. It shows white background 
         * replaced instead of the images. 
         */
        if (isSafari || isIOS || isMacOs || isMobileSafari) {
            await toBlob(inviteRef.current);
            await toBlob(inviteRef.current);
            newFile = await toBlob(inviteRef.current); // execute the function several times
        } else {
            newFile = await toBlob(inviteRef.current);
        }
        setInviteFile(newFile);
        notify.show("Image is ready", "success");
    }, [inviteRef]);

    useEffect(() => {
        if (debouncedCustomText.trim() !== "") {
            notify.show("Generating image", "success");
            makeImage()
        }
    }, [debouncedCustomText, previewImage, selectedInvite])

    const handleCustomText = (e) => setCustomText(e.target.value)
    return (
        <StyledPreview>
            <div className="preview">
                <div className="preview-title">Preview</div>
                {selectedInvite && (
                    <>
                        <StyledPreviewImage image={selectedInvite.src} ref={inviteRef}>
                           
                            <div className="preview-text">
                                {customText}
                            </div>
                        </StyledPreviewImage>
                    </>
                )}
            </div>
            <div className="customize">
                <label htmlFor="message">Message</label>
                <textarea id='message'
                    value={customText} 
                    maxLength={500} onChange={handleCustomText} 
                    placeholder="Write your testimony here" 
                    className="custom-text" 
                />
                <div className="form-action">
                    <button className='download-button' disabled={!inviteFile} onClick={downloadInvite}>
                        Download Image
                    </button>
                    {
                        !navigator.share && (
                            <button className='share-button' disabled={!inviteFile} onClick={handleShare}>Share</button>
                        )
                    }
                </div>
            </div>
        </StyledPreview>
    )
}

export default Preview;