import { useEffect } from "react";
import { StyledButton, StyledSamples } from './Samples.styled'
// change images base on event
import invite1 from "assets/images/e-invite.png";
import invite2 from "assets/images/e-invite2.png";
import invite3 from "assets/images/e-invite3.png";

const invites = [
  {
    name: "Invite 1",
    src: invite1
  },
  {
    name: "Invite 2",
    src: invite2
  },
  {
    name: "Invite 3",
    src: invite3
  }
];

const Samples = ({ handleSelectInvite, selectedInvite }) => {
  useEffect(() => {
    handleSelectInvite(invites[0]);
  }, [handleSelectInvite]);

  return (
    <StyledSamples>
      {invites.map((invite) => (
        <div key={invite.src}>
          <img
            key={invite.src}
            src={invite.src}
            style={{ objectFit: "contain" }}
            className={invite.src === selectedInvite?.src ? "selected" : ""}
            alt={invite.name}
            role="button"
            onClick={() => handleSelectInvite(invite)}
          />
          <StyledButton onClick={() => handleSelectInvite(invite)}>
            Choose sample
          </StyledButton>
        </div>
      ))}
    </StyledSamples>
  );
};

export default Samples;
