import styled from 'styled-components';
import { useState, useCallback } from "react";
import Samples from "./Samples";
import Preview from "./Preview";

const StyledInvite = styled.div`
    padding: 48px 100px 50px 100px;
    font-family: ${({ theme }) => theme.fonts.inter}, sans-serif;
    @media (max-width: 760px) {
        padding: 50px;
    }
    @media (min-width: 320px) and (max-width: 759px) {
        padding: 20px;
    }
    .divider {
        border: 1px solid ${({ theme }) => theme.colors.dividerGrey};
        margin: 50px 0;
    }
`;

const Invite = () => {
    const [selectedInvite, setSelectedInvite] = useState(null)
    const handleSelectInvite = useCallback((invite) => {
        setSelectedInvite(invite)
    }, [])
    return (
        <StyledInvite>
            <Samples handleSelectInvite={handleSelectInvite} selectedInvite={selectedInvite} />
            <div className="divider" />
            <Preview selectedInvite={selectedInvite} />
        </StyledInvite>
    )
};

export default Invite;