import styled from 'styled-components';
import dcLogo from 'assets/images/dc-logo.png';

const StyledFooter = styled.div`
    font-family: 'Inter';
    font-style: normal;
    font-weight: ${({ theme }) => theme.colors.normal};
    font-size: 24px;
    line-height: 29px;
    color: ${({ theme }) => theme.colors.white};
    height: 307px;
    background: ${({ theme }) => theme.colors.testimony};
    padding: 24px 0;
    display: grid;
    align-items: end;
    grid-row-gap: 30px;
    justify-content: center;
    font-family: ${({ theme }) => theme.fonts.inter}, sans-serif;
    img {
        height: 139px;
        margin: 0 auto;
    }
    .copyright {
        font-family: 'Inter';
        font-style: normal;
        font-weight: ${({ theme }) => theme.fontWeights.normal};
        font-size: ${({ theme }) => theme.fontSizes.large};
        line-height: 29px;
        color: ${({ theme }) => theme.colors.white};
        @media (max-width: 760px) {
            font-size: ${({ theme }) => theme.fontSizes.normal};
        }
    }
`;

const Footer = () => (
    <StyledFooter>
        <img src={dcLogo} alt="Dream Centre logo" />
        <div className="copyright">
            Copyright &copy; {new Date().getFullYear()}. All Rights Reserved
        </div>
    </StyledFooter>
);

export default Footer;