import styled from "styled-components";

export const Header = styled.h2`
  font-family: ${({ theme }) => theme.fonts.roboto}, sans-serif;
  font-size: ${({ theme }) => theme.fontSizes.large};
  color: ${({ theme }) => theme.colors.primary};
`;
export const StyledAbout = styled.div`
  padding: 48px 100px 150px 100px;
  font-family: ${({ theme }) => theme.fonts.inter}, sans-serif;
  overflow-x: hidden;
  @media (min-width: 425px) and (max-width: 760px) {
    padding: 50px;
  }
  @media (max-width: 425px) {
    padding: 10px;
  }
  .divider {
    border: 1px solid ${({ theme }) => theme.colors.dividerGrey};
    margin: 50px 0;
  }
  .youtube-container {
    display: grid;
    place-items: center;
    margin: 49px 0 78px 0;
  }
  .content {
    margin-bottom: 1.5rem;
    font-size: ${({ theme }) => theme.fontSizes.normal};
    line-height: 1.5em;
    color: ${({ theme }) => theme.colors.black};
    @media (min-width: 425px) and (max-width: 760px) {
      font-size: ${({ theme }) => theme.fontSizes.small};
    }
    @media (max-width: 425px) {
      font-size: ${({ theme }) => theme.fontSizes.small};
    }
  }
  .cta {
    display: grid;
    place-items: center;
  }
  .cta-button {
    background: ${({ theme }) => theme.colors.primary};
    border-radius: 5px;
    padding: 25px 100px;
    font-size: ${({ theme }) => theme.fontSizes.normal};
    color: ${({ theme }) => theme.colors.white};
    border: none;
    margin: 25px auto;
    cursor: pointer;
    text-decoration: none;
    text-transform: uppercase;
    display: grid;
    place-items: center;
    @media (max-width: 760px) {
      font-size: ${({ theme }) => theme.fontSizes.normal};
      padding: 15px 70px;
    }
  }
  .tel {
    padding-left: 5px;
    font-size: ${({ theme }) => theme.fontSizes.normal};
    display: inline-flex;
  }
`;

export const StyledTable = styled.div`
  .ministering {
    font-family: Arial, Helvetica, sans-serif;
    border-collapse: collapse;
    width: 100%;
    margin-bottom: 1.5rem;
  }

  .ministering td,
  .ministering th {
    border: 1px solid #ddd;
    padding: 10px;
  }

  .ministering tr:nth-child(even) {
    background-color: #f2f2f2;
  }

  .ministering tr:hover {
    background-color: #ddd;
  }

  .ministering th {
    padding-top: 16px;
    padding-bottom: 16px;
    text-align: left;
    background-color: ${({ theme }) => theme.colors.primary};
    color: white;
  }
`;