import { createGlobalStyle } from 'styled-components'
import Theme from "components/Theme";
import { Header, Banner, Footer, Invite, About } from "modules/home/components/index";
import Notifications from 'react-notify-toast';

const GlobalStyle = createGlobalStyle`
  body {
    margin: 0;
    padding: 0;
    background-color: #fff;
    font: 300 16px/22px "Roboto", "Helvetica Neue", Helvetica, Arial, sans-serif;
  }
`;

function Home() {
  return (
    <>
      <GlobalStyle />
      <Theme>
        <Notifications />
        <Header />
        <Banner />
        <Invite />
        <About />
        <Footer />
      </Theme>
    </>
  );
}

export default Home;
