const DEFAULT_INVITE_TEXT = `You are specially invited to the
ANNUAL GOD OF BREAKTHROUGHS CONVENTION & HOMECOMING (GOBC&H)`;
const IMAGE_DOWNLOAD_FILE = "GOBC&H 2024 invite.png";
const IMAGE_DOWNLOAD_TITLE = "GOBC&H 2024";
const BANNER_TITLE = " ANNUAL GOD OF BREAKTHROUGHS CONVENTION & HOMECOMING (GOBC&H) 2024";
const BANNER_PARAGRAPH =
  `You are specially invited to the upcoming Anointed Feast of God for mankind`;

const EVENT_TITLE = "ANNUAL GOD OF BREAKTHROUGHS CONVENTION & HOMECOMING (GOBC&H) 2024";
const EVENT_THEME = `THE HOLY SPIRIT AND YOU 2.0`
const EVENT_DATE = [
  {
    fulldate: '30th June 2024',
    shortdate: '2024-06-30'
  },
  {
    fulldate: '7th July 2024',
    shortdate: '2024-07-07'
  },
]
const EVENT_TIME = "6am-8am, 8am-10am, 10am-12noon, 12noon-2pm and 4pm-9pm daily (all GMT +1)"
const REGISTER_LINK = "https://gobch.lifeoasisinternationalchurch.org/";

const GUESTS_LIST = [
  {
    name: "Rev. Olusola Areogun",
    brief:
      "Olusola Ayodele Areogun is an ordained minister of the gospel. He serves the will of God in this generation as author,"
  },
  {
    name: "Rev. Oyenike Areogun",
    brief:
      "Oyenike Areogun is an ordained minister of the Gospel. She serves the will of God in this generation as an"
  },
  {
    name: "Rev. (Dr.) Francis Olonade",
    brief:
      "General Overseer, The Jesus is Lord Foundation International, Modakeke, Osun State, Nigeria"
  },
  {
    name: "Rev. (Dr.) John Idowu",
    brief:
      "General Overseer, The Name of Jesus Ministries, Ado Ekiti, Ekiti State, Nigeria"
  }, {
    name: "Rev. (Dr.) Lekan Babatunde",
    brief:
      "General Overseer, Jesus is King Ministry, Ibadan, Oyo State, Nigeria"
  },
  {
    name: "Rev. Akinlolu Raphael",
    brief:
      "General Overseer, World Harvest Network International Ministries Ibadan, Oyo State, Nigeria"
  },
  {
    name: "Pastor Tunde Jaiyebo",
    brief:
      "Senior Pastor, Charis Family International Church, Ibadan, Oyo State, Nigeria"
  },
];

// Information resevered for event when other guests are invited
const EXTERNAL_GUESTS = [
  `Rev. (Dr.) Francis Olonade -	General Overseer, The Jesus is Lord Foundation International, Modakeke, Osun State, Nigeria`,
  `Rev. (Dr.) John Idowu	General Overseer, The Name of Jesus Ministries, Ado Ekiti, Ekiti State, Nigeria`,
  `Rev. (Dr.) Lekan Babatunde	General Overseer, Jesus is King Ministry, Ibadan, Oyo State, Nigeria`,
  `Rev. Akinlolu Raphael	General Overseer, World Harvest Network International Ministries Ibadan, Oyo State, Nigeria`,
  `Pastor Tunde Jaiyebo	Senior Pastor, Charis Family International Church, Ibadan, Oyo State, Nigeria`,
];

export {
  EVENT_TITLE,
  EVENT_THEME,
  EVENT_DATE,
  EVENT_TIME,
  BANNER_TITLE,
  BANNER_PARAGRAPH,
  IMAGE_DOWNLOAD_FILE,
  DEFAULT_INVITE_TEXT,
  IMAGE_DOWNLOAD_TITLE,
  GUESTS_LIST,
  EXTERNAL_GUESTS,
  REGISTER_LINK,
};